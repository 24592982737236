<template>
  <div class="search-results">
    <div class="helper-text">
      <p v-if="!searchText && !filteredResults.length">
        Start by using the search input above.
      </p>
      <p v-else-if="searchText && !results.length">No results</p>
    </div>

    <div class="results-list">
      <div
        v-for="result in filteredResults"
        :key="result.id"
        class="result-item"
      >
        <router-link :to="result.uri">{{ result.title }}</router-link>
        <span>{{ result.url }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResults',
  props: {
    searchText: {
      type: String,
      required: false,
      default: ''
    },
    results: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    filteredResults() {
      return this.results
        .filter(result => result !== false)
        .map(result => {
          result.uri = result.uri === '__home__' ? '/' : result.uri
          return result
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-results {
  padding: 30px 0 120px 0;

  .results-list {
    max-width: 900px;
    margin: 0 auto;

    .result-item {
      @apply text-left my-6;

      a,
      a:visited {
        color: green;
      }

      span {
        @apply block;
      }
    }
  }
}
</style>

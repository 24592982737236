<template>
  <div class="search-container">
    <label for="searchText" class="sr-only">Search Text</label>
    <div class="input-container">
      <input
        type="text"
        id="searchText"
        placeholder="Type here"
        @input="initiateSearch"
        v-model="searchText"
      />
      <span class="searching-indicator" :class="{ searching: searching }">
        <font-awesome-icon class="search-icon" icon="search" />
        Searching
      </span>
    </div>

    <SearchResults :search-text="searchText" :results="searchResults" />
  </div>
</template>

<script>
import api from '@/api'
import SearchResults from '@/components/Search/SearchResults'

export default {
  name: 'SearchContainer',
  components: { SearchResults },
  data() {
    return {
      searchText: '',
      searchTimeout: null,
      searching: false,
      searchResults: [false]
    }
  },
  methods: {
    initiateSearch() {
      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(this.startSearch, 1000)
    },
    startSearch() {
      if (!this.searchText) {
        this.searchResults = [false]
        return
      }

      this.searching = true

      api.base.searchContent(this.searchText).then(response => {
        this.searchResults = response.entries || []
        this.searching = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-container {
  margin-left: 15px;
  margin-right: 15px;

  .input-container {
    @apply relative;
    max-width: 800px;
    margin: 0 auto;

    input {
    }

    span.searching-indicator {
      @apply absolute;
      right: 6px;
      top: 10px;
      opacity: 0;
      transition: opacity 300ms linear;

      &.searching {
        opacity: 1;
        animation: pulse 1.5s infinite;
        animation-delay: 300ms;
      }
    }

    @keyframes pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>

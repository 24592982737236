<template>
  <div id="searchPage">
    <h2>{{ pageData.title }}</h2>
    <SearchContainer />
  </div>
</template>

<script>
import api from '@/api'
import viewMixin from '@/mixins/viewMixin'
import SearchContainer from '@/components/Search/SearchContainer'

export default {
  name: 'Search',
  components: { SearchContainer },
  mixins: [viewMixin],
  data() {
    return {
      pageData: []
    }
  },
  mounted() {
    this.loadSearchPage()
  },
  methods: {
    loadSearchPage() {
      api.base
        .loadSearchPage()
        .then(response => {
          this.pageData = response.entry || {}
        })
        .then(this.toggleShowPageTrue)
    }
  }
}
</script>

<style lang="scss" scoped>
#searchPage {
  padding: 80px 0 240px;

  h2 {
    font-size: 55px;
  }

  p {
    font-size: 18px;
  }
}
</style>
